
import { Component, Vue } from "vue-property-decorator";
import { AuthInterceptor } from "@/config";
import { UsersApi } from "@fundraisingbureauet/admin-typescript-client/apis/UsersApi";
import Modal from "@/components/shared/layout/Modal.vue";
import { eventHub } from "@/router";

@Component({ components: { Modal } })
export default class CreateUserModal extends Vue {
  username = "";
  password = "";

  closeModal() {
    //  Emit
    this.$emit("close-modal");
  }

  createUser() {
    new UsersApi(AuthInterceptor.Instance)
      .createUser({
        createUser: { name: this.username, password: this.password },
      })
      .subscribe(
        () => {
          this.$emit("userCreated");
        },
        (error) => {
          eventHub.$emit("httpError", error);
        }
      );
  }
}


import ElementList, {
  ListColumn,
} from "@/components/shared/layout/ElementList.vue";
import CreateUserModal from "@/components/users/CreateUserModal.vue";
import { Component, Vue } from "vue-property-decorator";
import { UsersApi } from "@fundraisingbureauet/admin-typescript-client/apis/UsersApi";
import { User } from "@fundraisingbureauet/admin-typescript-client/models";
import { AuthInterceptor } from "@/config";
import { eventHub } from "@/router";

@Component({
  components: {
    CreateUserModal,
    ElementList,
  },
})
export default class UserList extends Vue {
  users: User[] = [];
  createUserModal = false;

  columns: ListColumn<User>[] = [
    {
      header: "Name",
      key: "name",
      field: "name",
    },
  ];

  mounted() {
    this.loadUsers();
  }

  reload() {
    this.createUserModal = false;
    this.loadUsers();
  }

  private loadUsers() {
    new UsersApi(AuthInterceptor.Instance).getUserList().subscribe(
      (value) => {
        this.users = value;
      },
      (error) => {
        eventHub.$emit("httpError", error);
      }
    );
  }

  showCreateUserModal() {
    this.createUserModal = true;
  }

  closeCreateUserModal() {
    this.createUserModal = false;
  }
}
